import React from 'react'
import status from '../images/status-icon.svg'
import { Link } from 'react-router-dom'

const FooterLogin = () => {
  return (
    <div className='footer-organization-page'>
      <div className=' term-oraganization'>
        <ul>
          <li><Link  to="https://ilmiya.com/terms-of-services" target='blank' >Terms </Link></li>
          <li><Link to="https://ilmiya.com/privacy-policy" target='blank' >Privacy </Link></li>
          {/* <li><Link  to="https://support.ilmiya.com/hc/en-us" target='blank'> Support  </Link></li> */}
        </ul>
      </div>
      <div className='main-status'>
        <Link to="https://status.ilmiya.com/" target="_blank" className='circle-status'>
          <img src={status} alt="img" />
          Status
        </Link>
      </div>
    </div>
  )
}

export default FooterLogin
