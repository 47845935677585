import React, { useEffect, useState } from "react";
// import Breadcrumbs from "../Components2/Breadcrumbs";
// import ProfileCard from "../Components2/ProfileCard";
import ProfileForm from "../screens/ProfileForm";
import { Formik } from "formik";
import * as yup from "yup";
import {
  getProfileAsyncThunk,
  getUserAsyncThunk,
  updateUserAsyncThunk,
} from "../components/userSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loadingdata from "../screens/loadingdata";
import Breadcrumbs from "./Breadcrumbs";
import ProfileCard from "./ProfileCard";

export default function ProfileUpdateScreen() {
  const user = useSelector((s) => s.user.story);
  console.log("🚀 ~ ProfileUpdateScreen ~ user:", user)
  const [profileImage, setProfileImage] = useState(user?.photoURL);
  const [show, setShow] = React.useState(false);

  const [userDetails, setUserDetails] = React.useState({
    given_name: "",
    displayName: "",
    bio: "",
    phone_number: "",
    username: "",
    email: "",
    password: "",
    family_name: "",
    dob: "",
    // grade: "",
    gender: "",
    pronounce: "",
    id_card_number: "",
    photoURL: "",
  });
  console.log("userDetails", userDetails);
  const [isLoading, setIsLoading] = React.useState(false);
  const { user_id } = useParams();
  const d = useDispatch();


  useEffect(() => {
    setProfileImage(user?.photoURL);
  }, [user]);

  const getProfileLoading = useSelector(
    (s) => s.user.loadings.getProfileAsyncThunk
  );
  console.log("user11", user);

  useEffect(() => {
    setIsLoading(getProfileLoading);
  }, [getProfileLoading]);

  const UserSchemaIn = yup.object().shape({
    given_name: yup.string().required("First name is required"),
    family_name: yup.string().required("Last name is required"),
    displayName: yup.string().required("Display name is required"),
    bio: yup.string().required("Bio is required"),
    phone_number: yup.string().max(15).min(10).nullable().required("Phone number is required"),
    email: yup.string().email("Invalid email"),
    username: yup.string().required("Invalid Username"),
    password: yup.string().min(6, "Password must be at least 6 character long"),
    // grade: yup.string(),
    gender: yup.string(),
    pronounce: yup.string(),
    dob: yup.string().required("Date of birth is required"),
    id_card_number: yup.string().required("ID required"),
    photoURL: yup.string().required("Profile photo is required"),
  });

  const initialValues = {
    given_name: user?.given_name || "",
    displayName: user?.displayName || "",
    bio: user?.bio || "",
    phone_number: user?.phone_number || "",
    username: user?.username || "",
    email: user?.email,
    password: user?.password || "",
    family_name: user?.family_name || "",
    dob: user?.dob || "",
    // grade: user?.grade,
    gender: user?.gender || "male",
    pronounce: user?.pronounce || "mr",
    id_card_number: user?.user_id || "",
    photoURL: user?.photoURL || "",
  };

  const handleChange1 = (e) => {
    if (e?.target) {
      setUserDetails({
        ...userDetails,
        [e.target.name]: e.target.value,
      });
    } else {
      setUserDetails({
        ...userDetails,
        photoURL: e
      })
    }
  };

  useEffect(() => {
    d(getProfileAsyncThunk());
  }, []);

  useEffect(() => {
    d(getUserAsyncThunk(user_id));
  }, [user_id]);

  const updateHandle = (userDetails) => {
    if (userDetails?.password && userDetails?.password?.length < 6) {
      toast.error("Password must be 6 character long");
      return;
    }

    // if (!userDetails?.password) delete userDetails?.password;
    // if (!userDetails?.dob) delete userDetails?.dob;
    // // if (!userDetails?.grade) delete userDetails.grade;
    // if (!userDetails?.gender) delete userDetails?.gender;

    // console.log("🚀 ~ updateHandle ~ userDetails:", userDetails);

    const updatedUser = {
      ...userDetails,
      email: undefined, 
      photoURL: profileImage,
      password: userDetails?.password || user?.password,
      bio: userDetails?.bio || user?.bio, 
      id_card_number: userDetails?.id_card_number || user?.id_card_number, 
    };

    d(
      updateUserAsyncThunk({
        // data: {
        //   ...userDetails,
        //   email: undefined,
        //   photoURL: profileImage,
        // },
        data: updatedUser,
        id: user?.user_id,
        callBack: () => {
          d(getUserAsyncThunk(user?.user_id));
          toast.success("Updated Profile Successfully");
          // window.location.reload();
        },
      })
    );
  };

  useEffect(() => {
    if (user) {
      setUserDetails({
        given_name: user?.given_name,
        displayName: user?.displayName,
        bio: user?.bio,
        username: user?.username,
        email: user?.email,
        password: user?.password || "",
        family_name: user?.family_name,
        dob: user?.dob,
        // grade: user?.grade || "1",
        gender: user?.gender || "male",
        pronounce: user?.pronounce || "mr",
        id_card_number: user?.id_card_number || "0",
        photoURL: profileImage,
      });
    }
  }, [user, profileImage]);

  useEffect(() => {
    if (
      user?.given_name !== userDetails?.given_name ||
      user?.displayName !== userDetails?.displayName ||
      user?.bio !== userDetails?.bio ||
      user?.photoURL !== profileImage ||
      user?.family_name !== userDetails?.family_name ||
      user?.dob !== userDetails?.dob ||
      // user?.grade !== userDetails?.grade ||
      user?.gender !== userDetails?.gender ||
      user?.pronounce !== userDetails?.pronounce ||
      user?.id_card_number !== userDetails?.id_card_number ||
      "" !== userDetails.password
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [userDetails, user]);

  return (
    <div lassName="profile-page student-profile-page">
      {isLoading ? (
        <Loadingdata />
      ) : (
        <div className="row">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={updateHandle}
            validationSchema={UserSchemaIn}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div className="col-12">
                <ProfileForm
                  photoURL={profileImage}
                  show={show}
                  user={user}
                  setPhotoURL={setProfileImage}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  // user={userDetails}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange1={handleChange1}
                />
              </div>
            )}
          </Formik>
        </div>

      )}
    </div>
  );
}
