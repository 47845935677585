import React from "react";
import { auth } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { countUserEvent } from "../utils/utlis";
import logo from "../images/logo/logo.png";
import Dropdown from "react-bootstrap/Dropdown";
import avatar from "../images/avatar.png";
import { redirect_uris } from "../constants";
import { Link, useLocation } from "react-router-dom";
import { LuUser2 } from "react-icons/lu";
import { MdOutlineSettings } from "react-icons/md";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { LuLogOut } from "react-icons/lu";
import { FaQuestion } from "react-icons/fa6";
import help from "../images/header.svg";
import PermIdentitySharpIcon from "@mui/icons-material/PermIdentitySharp";
import logout1 from '../images/logout.svg'
import { useSelector } from "react-redux";
import avatarUmme from "../images/avatar.svg";


export default function Logout({ children }) {
  const { currentUser: user } = auth;
  console.log("🚀 ~ Logout ~ user:", user?._delegate?.uid);
  const navigate = useNavigate();
  const profile = useSelector((s) => s.user.story);

  const [isIntercomOpen, setIsIntercomOpen] = React.useState(false);

  const accountHandler = () => {
    console.log("profile111");
    window.open(redirect_uris.Login, "_blank");
  };

  const handleToggleIntercom = () => {
    if (window.Intercom) {
      if (isIntercomOpen) {
        window.Intercom("hide");
    } else {
        window.Intercom("show");
      }
      setIsIntercomOpen(!isIntercomOpen);
    } else {
      console.error("Intercom is not initialized.");
    }
  };

  return user ? (
    <>
      <div className="header">
        <div className="row">
          <div className="col-6 col-md-6 align-self-center">
            <div className="section1">
              <Link to="/organizations">
                <img src={logo} alt="info" style={{ borderRadius: 4 }} />
              </Link>
            </div>
          </div>
          <div className="col-6 col-md-6 align-self-center">
            <div className="section2">
              <div className="starIcon" onClick={handleToggleIntercom}>
            <a href="#" >
              <img src={help} alt="star" />
            </a>
          </div>
              <div>
                <button className="avatar-icon">
                  <Dropdown direction="end">
                    <Dropdown.Toggle id="dropdown-basic" style={{ border: "none" }}>
                      <img
                        style={{
                          width: "36px",
                          height: "36px",
                          borderRadius: "50%",
                        }}
                        src={profile?.photoURL || user?.photoURL || avatarUmme}
                      // alt="Avtar"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-end dropdown-menu-avtar p-0">
                      <div className="menuItems">
                        <div className="dropdown-heading">
                          <img
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50px",
                            }}
                            src={profile?.photoURL || user?.photoURL || avatarUmme}
                            alt="Avtar"
                          />
                          <strong>{auth?.currentUser?.displayName}</strong>
                        </div>

                        <div className="mainItems">
                          <div className="items">
                            <div className="icons-1">
                              {/* <LuUser2 /> */}
                              <PermIdentitySharpIcon />
                            </div>

                            <Link to={`/profile/${user?._delegate?.uid}`}>
                              <p>Profile</p>
                            </Link>
                          </div>

                          {/* <div className="items">
                        <div className="icons-1">
                          <MdOutlineAdminPanelSettings />
                        </div>
                        <a target="_blank" href={redirect_uris?.Admin}>
                          Admin
                        </a>
                      </div> */}
                        </div>

                        <div className="mainItems">
                          <div
                            className="items"
                            onClick={() => {
                              countUserEvent("Logout", { eventName: "Logout" });
                              auth.signOut().then(() => navigate("/"));
                            }}
                          >
                            <div className="icons-1">
                              <img src={logout1} alt="logout" />
                              {/* <LuLogOut /> */}
                            </div>
                            <p
                              onClick={() => {
                                countUserEvent("Logout", { eventName: "Logout" });
                                auth.signOut().then(() => navigate("/"));
                              }}
                            >
                              Logout
                            </p>
                          </div>

                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="online-status"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  ) : (
    { children }
  );
}
