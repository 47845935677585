import React, { useState } from "react";
import { Form } from "react-bootstrap";
import ErrorMassage from "../components/ErrorMassage";
import HeaderBottomOpenPage from "../components/HeaderBottomOpenPage";
import ChangePasswordModal from "../components/ChangePasswordModal";
import ProfileCard from "./ProfileCard";
import "react-calendar/dist/Calendar.css";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';



export default function ProfileForm({
  handleChange,
  handleChange1,
  values,
  errors,
  touched,
  user,
  show,
  handleSubmit,
  setPhotoURL,
  photoURL,
  setFieldValue,
}) {
  const [isShow, setIsShow] = useState(false);
  const [value, setValue] = useState(new Date());

  const handleClose = () => setIsShow(false);

  const [activeTab, setActiveTab] = useState("tab1");
  const getButtonClass = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="settings-page-wrapper">
        <HeaderBottomOpenPage
          activeTab={activeTab}
          getButtonClass={getButtonClass}
        />
        <div className="setting-main-wrapper">
        {activeTab === "tab1" && (
          <>
            {/* <div> */}
            <ProfileCard
              show={show}
              user={user}
              handleSubmit={handleSubmit}
              setPhotoURL={setPhotoURL}
              photoURL={photoURL}
              handleChange1={handleChange1}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
            <div className="container-fluid p-0">
              <div className="page-content new-profile-form">
                <div className="information-cards-wrapper information-top-space">
                  <div className="single-information-card">
                    <div className="row">
                      <div className="col-4">
                        <div className="info-title">
                          <h3>Personal Info</h3>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="info-section-wrapper">
                          <div className="single-info">
                            <p>First Name</p>
                            <div className="single-info-input-wrapper">
                              {console.log("values1345", values)}
                              <input
                                type="text"
                                controlId="outlined-basic"
                                label="First name"
                                name="given_name"
                                variant="outlined"
                                placeholder="First name"
                                value={values?.given_name}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "given_name",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                              />
                              <div className="error-msg">
                                <ErrorMassage
                                  error={errors?.given_name}
                                  visible={touched?.given_name}
                                  className='error'
                                />
                              </div>
                            </div>
                          </div>
                          <div className="single-info">
                            <p>Last Name</p>
                            <div className="single-info-input-wrapper">
                              <input
                                controlId="outlined-basic"
                                label="Last name"
                                name="family_name"
                                variant="outlined"
                                type="text"
                                placeholder="Last Name"
                                value={values?.family_name}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "family_name",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                              />
                              <div className="error-msg">
                                <ErrorMassage
                                  error={errors?.family_name}
                                  visible={touched?.family_name}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="single-info">

                            <p>Username</p>
                            <div className="single-info-input-wrapper">
                              <input
                                controlId="outlined-basic"
                                label="Username"
                                variant="outlined"
                                disabled
                                value={values?.username}
                              />
                            </div>
                          </div>
                          <div className="single-info">
                            <p>Bio</p>
                            <div className="single-info-input-wrapper">
                              <textarea
                                name="bio"
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "bio",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                                value={values?.bio}
                                placeholder="Bio"
                              ></textarea>
                              <div className="error-msg id-error">
                                <ErrorMassage
                                  error={errors?.bio}
                                  visible={touched?.bio}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="single-info">
                            <p>id no.</p>
                            <div className="single-info-input-wrapper">
                              <input
                                controlId="outlined-basic"
                                label="id no."
                                variant="outlined"
                                type="text"
                                name="id_card_number"
                                placeholder="Id"
                                value={values?.id_card_number}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "id_card_number",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                                disabled={true}
                              />
                              <div className="error-msg id-error">
                                <ErrorMassage
                                  error={errors?.id_card_number}
                                  visible={touched?.id_card_number}
                                />
                              </div>

                            </div>
                          </div>

                          <div className="single-info">
                            <label htmlFor="Name">Date of birth</label>
                            <div className="single-info-input-wrapper">
                              <div className="main-calender">

                                <DatePicker
                                  maxDate={new Date()}
                                  onChange={(date) => {
                                    setValue(date); // Update local state
                                    const adjustedDate = new Date(date);
                                    adjustedDate.setMinutes(adjustedDate.getMinutes() - adjustedDate.getTimezoneOffset()); // Adjust for timezone
                                    const formattedDate = adjustedDate.toISOString().split("T")[0]; // Format the date
                                    setFieldValue("dob", formattedDate); // Update Formik state
                                    handleChange1({ target: { name: "dob", value: formattedDate } }); // Update userDetails state
                                  }}
                                  value={values.dob ? new Date(values.dob) : null}
                                />
                              </div>
                              <div className="error-msg id-error">
                                <ErrorMassage
                                  error={errors?.dob}
                                  visible={touched?.dob}
                                />
                              </div>
                            </div>

                            {/* <p>Date of Birth</p>
                            <div>
                              <input
                                controlId="outlined-basic"
                                label="Date oF Birth"
                                variant="outlined"
                                className="mb-3"
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "dob",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                                type="Date"
                                name="dob"
                                value={
                                  values.dob ? values?.dob.split("T")[0] : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <ErrorMassage
                                error={errors?.dob}
                                visible={touched?.dob}
                              />
                            </div> */}

                          </div>

                          <div className="single-info">
                            <p>Gender</p>

                            <div className="single-info-input-wrapper">
                              <div className="dropdown-container">
                                <select
                                  name="gender"
                                  className="custom-dropdown"
                                  value={values.gender}
                                  onChange={(e) => {
                                    handleChange({
                                      target: {
                                        name: "gender",
                                        value: e.target.value,
                                      },
                                    });
                                    handleChange1(e);
                                  }}
                                >

                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                </select>
                                <div className="error-msg">
                                  <ErrorMassage
                                    error={errors?.gender}
                                    visible={touched?.gender}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="single-info">
                            <p>Display Name</p>
                            <div className="single-info-input-wrapper">
                              {console.log("values1345", values)}
                              <input
                                type="text"
                                controlId="outlined-basic"
                                label="Bahaji"
                                name="displayName"
                                placeholder="Display name"
                                variant="outlined"
                                value={values?.displayName}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "displayName",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                              />
                              <div className="error-msg">
                                <ErrorMassage
                                  error={errors?.displayName}
                                  visible={touched?.displayName}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="single-info">
                            <p>Title</p>

                            <div className="single-info-input-wrapper">
                              <div className="dropdown-container">
                                <select
                                  name="pronounce"
                                  className="custom-dropdown"
                                  value={values.pronounce}
                                  onChange={(e) => {
                                    handleChange({
                                      target: {
                                        name: "pronounce",
                                        value: e.target.value,
                                      },
                                    });
                                    handleChange1(e);
                                  }}
                                >

                                  <option value="mr">Mr.</option>
                                  <option value="mrs">Mrs.</option>
                                </select>
                                <div className="error-msg">
                                  <ErrorMassage
                                    error={errors?.pronounce}
                                    visible={touched?.pronounce}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="single-info">
                            <p>Phone</p>
                            <div className="single-info-input-wrapper">
                              <input
                                type="number"
                                controlId="outlined-basic"
                                placeholder="+1 364 645 6568"
                                label="Bahaji"
                                name="phone_number"
                                variant="outlined"
                                value={values?.phone_number}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "phone_number",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                              />
                              <div className="error-msg">
                                <ErrorMassage
                                  error={errors?.phone_number}
                                  visible={touched?.phone_number}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="single-info">
                            <p>Title</p>

                            <div className="single-info-input-wrapper">
                              <div className="dropdown-container">
                                <select
                                  name="title"
                                  className="custom-dropdown"
                                  value={values.title}
                                  onChange={(e) => {
                                    handleChange({
                                      target: {
                                        name: "title",
                                        value: e.target.value,
                                      },
                                    });
                                    handleChange1(e);
                                  }}
                                >

                                  <option value="mr">Mr.</option>
                                  <option value="mrs">Mrs.</option>
                                </select>
                                <div className="error-msg">
                                <ErrorMassage
                                  error={errors?.title}
                                  visible={touched?.title}
                                />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="single-info">
                            <p>Phone</p>
                            <div className="single-info-input-wrapper">
                              <input
                                type="number"
                                controlId="outlined-basic"
                                placeholder="+1 364 645 6568"
                                label="Bahaji"
                                name="phone_number"
                                variant="outlined"
                                value={values?.phone_number}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "phone_number",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                              />
                              <ErrorMassage
                                error={errors?.phone_number}
                                visible={touched?.phone_number}
                              />
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {activeTab === "tab2" && (
          <div className="container-fluid p-0">
            <div className="page-content">
              <div className="page-title new-page-title">
                <h1>Profile Overview</h1>
              </div>
              <div className="information-cards-wrapper">
                <div className="single-information-card">
                  <div className="row">
                    <div className="col-4">
                      <div className="info-title">
                        <h3>Login</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div
                        className="info-section-wrapper "
                        style={{ padding: "0" }}
                      >
                        <div className="head-sec">
                          <span>Login info</span>
                        </div>
                        <div className="single-info-parent">
                          <div className="single-info">
                            <div className="single-info-comp1">
                              <p className="text1">Email</p>
                              <p className="text2">Here are student email</p>
                            </div>
                            <div className="single-info-input-wrapper">
                              <input
                                type="text"
                                placeholder="Thaha@gmail.com"
                                controlId="outlined-basic"
                                variant="outlined"
                                value={values?.email}
                                className="mb-3"
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="single-info">
                            <div className="single-info-comp1">
                              <p className="text1">Password</p>
                              <p className="text2">Here are student Password</p>
                            </div>
                            <div className="single-info-input-wrapper">
                              <input
                                type="password"
                                onClick={() => setIsShow(true)}
                                controlId="outlined-basic"
                                variant="outlined"
                                value={"595883"}
                                className="mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === "tab3" && (
          <div className="container-fluid p-0">
            <div className="page-content">
              <div className="page-title">
                <h1>Profile Overview</h1>
              </div>
              <div className="information-cards-wrapper">
                <div className="single-information-card">
                  <div className="row">
                    <div className="col-4">
                      <div className="info-title">
                        <h3>Notifications</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="info-section-wrapper">
                        <div className="single-info">
                          <p>Inbox</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>Email</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>Desktop</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className="info-title">
                        <h3>Email Updates</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="info-section-wrapper">
                        <div className="single-info">
                          <p>Invite accepted</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>Privacy and legal updates</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>DPA updates</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>Linear changelog email</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>Linear changelog in sidebard</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        </div>
        <ChangePasswordModal isShow={isShow} handleClose={handleClose} />
      </div>
    </>
  );
}
