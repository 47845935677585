import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
// import imliya from "../../../../images/img/ilmiya.png"
import { useDispatch } from "react-redux";
import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";
import { IoMdAdd } from "react-icons/io";
// import FilterBar from '../../../LXPComponents/FilterBar'
import { Formik } from "formik";
import ChooseAvatarModal from "../components/modals/ChooseAvatarModal.jsx";
import CustomButton from "../components/customComponents/CustomButton";
// import { handleModel } from '../redux/layoutSlices/modelSlice'
import AvaterCard from "../components/AvaterCard";
// import { parseUrlFromModelAvatar } from '../helpers/asset'
import { getUserAsyncThunk, updateUserAsyncThunk } from "../components/userSlice.js";
import NoDataFound from "../components/NoDataFound";
import { ProfileSchema } from "../components/Validation";
// import FilterBar from '../components/bars/FilterBar.js'
import { parseUrlFromModelAvatar } from "../utils/asset.js";
import FilterBar from "../components/FilterBar.js";
import { handleModel } from "../components/modelSlice.js";
import ErrorMassage from "../components/ErrorMassage.jsx";
import avatar from "../images/avatar.png";
import avatarUmme from "../images/avatar.svg";

function ProfileCard({
  user,
  show,
  handleSubmit,
  handleChange1,
  setPhotoURL,
  photoURL,
  errors,
  touched,
  setFieldValue,
}) {
  const [selectedAvatar, setSelectedAvatar] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [lgShow, setLgShow] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  // const [photoURL, setPhotoURL] = useState();
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.RightSidebarBase
  );
  const modelState = useSelector((s) => s?.model?.modelState?.chooseAvatar);
  console.log("modelState", modelState);
  const getUserProfile = useSelector((s) => s.user.profile);
  const userDataLoading = useSelector((s) => s.user.loadings.getUserAsyncThunk);
  // console.log("getUserProfile", getUserProfile)
  const oldData = modelArgs?.args?.user;
  console.log("oldData", oldData);
  // const { currentUser: user } = getAuth();

  const d = useDispatch();
  // const organization = JSON.parse(localStorage?.getItem("space"));
  const openUpdateProfile = () => {
    setLgShow(true);
    // d(
    //   handleModel({
    //     model: "RightSidebarBase",
    //     state: true,
    //     args: { resource: "RightSidebarBase", args: { user } },
    //   })
    // );
  };

  const closeChooseAvatar = () => {
    d(
      handleModel({
        model: "chooseAvatar",
        state: false,
        args: { resource: "chooseAvatar" },
      })
    );
  };

  const openContent = () => {
    d(
      handleModel({
        model: "chooseAvatar",
        state: true,
        args: {
          type: "picture",
          picture: null,
          callBack: (e) => {
            if (!e) {
              return;
            }
            const imgURl = parseUrlFromModelAvatar(e);
            setPhotoURL(imgURl);
            handleChange1(imgURl);
            setFieldValue("photoURL", imgURl);
          },
        },
      })
    );
    setLgShow(false);
  };

  const forderArray = [
    { name: "Planetary", key: "1-Planetary-Patch" },
    { name: "Interstellar", key: "2-Interstellar-Patch" },
    { name: "Marine", key: "3-Marine-Patch" },
    { name: "Pinnacle", key: "4-Pinnacle-Patch" },
    { name: "Blossom", key: "5-Blossom-Patch" },
    { name: "Terra", key: "6-Terra-Patch" },
    { name: "Earth", key: "7-Earth-Patch" },
    { name: "Timber", key: "8-Timber-Patch" },
    { name: "Design", key: "9-Design-Patch" },
    { name: "Climate", key: "10-Climate-Patch" },
    { name: "Monuments", key: "11-Monuments" },
  ];

  let fullNameArray = oldData?.displayName?.split(" ");
  let firstName = fullNameArray?.[0];
  let lastName = fullNameArray?.[fullNameArray?.length - 1];

  const updateProfileHandler = (data) => {
    if (oldData) {
      const formData = new FormData();
      // formData.append("name", data.name);
      formData.append(
        "displayName",
        data?.displayName.split(" ")?.[0] + " " + data.last_name
      );
      // formData.append("family_name", data.family_name);
      // formData.append("nickname", data.nickname);

      if (photoURL) {
        formData.append("photoURL", parseUrlFromModelAvatar(photoURL));
      }
      d(
        updateUserAsyncThunk({
          data: formData,
          id: oldData?.tenantId,
          callBack: () => {
            // window.location.reload();
            d(getUserAsyncThunk())
          },
        })
      );
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    console.log("Search term:", value);
    const filtered = forderArray?.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredItems(forderArray);
    }, 1000); // 1 second delay

    return () => clearTimeout(timer);
  }, [lgShow]);

  return (
    <div>
      {/* <div className="container"> */}
      <div className="settings-page-wrapper">
        <div className="container-fluid p-0">
          <div className="page-content new-profile-card">
            <div className="page-title new-page-title">
              <h1>Profile Overview</h1>
              <div style={{ position: "relative" }}>
                {show && (
                  <CustomButton
                    loadingText={"Saving..."}
                    className="bg-primary"
                    title={"Save"}
                    action={"updateUserAsyncThunk"}
                    reducer={"user"}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </div>
            <div className="information-cards-wrapper">
              <div className="single-information-card">
                <div className="row">
                  <div className="col-md-4">
                    <div className="field-title">
                      <h4>Profile photo</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="information-content">
                      <div className="single-information">
                        <div className="title profile-title">
                          <h5>Profile photo</h5>
                        </div>
                        <div className="error-handle">
                        <div className="profile-image">
                          <div
                            className="img-wrapper "
                            style={{ position: "relative" }}
                          >
                            <img
                              src={
                                photoURL ||
                                user?.photoURL ||
                                avatarUmme
                              }
                              alt="Profile"
                              style={{ height: '100px' }}
                            />

                            <span
                              onClick={openUpdateProfile}
                              style={{
                                position: "absolute",
                                bottom: 0,
                                right: 7,
                                cursor: "pointer",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle
                                  cx="16"
                                  cy="16"
                                  r="15.5"
                                  fill="white"
                                  stroke="#DEE1E6"
                                />
                                <mask
                                  id="mask0_6936_122421"
                                  maskUnits="userSpaceOnUse"
                                  x="7"
                                  y="7"
                                  width="18"
                                  height="18"
                                >
                                  <rect
                                    x="7"
                                    y="7"
                                    width="18"
                                    height="18"
                                    fill="#D9D9D9"
                                  />
                                </mask>
                                <g mask="url(#mask0_6936_122421)">
                                  <path
                                    d="M10.75 21.25H11.8187L19.15 13.9187L18.0813 12.85L10.75 20.1812V21.25ZM9.25 22.75V19.5625L19.15 9.68125C19.3 9.54375 19.4656 9.4375 19.6469 9.3625C19.8281 9.2875 20.0188 9.25 20.2188 9.25C20.4187 9.25 20.6125 9.2875 20.8 9.3625C20.9875 9.4375 21.15 9.55 21.2875 9.7L22.3188 10.75C22.4688 10.8875 22.5781 11.05 22.6469 11.2375C22.7156 11.425 22.75 11.6125 22.75 11.8C22.75 12 22.7156 12.1906 22.6469 12.3719C22.5781 12.5531 22.4688 12.7188 22.3188 12.8688L12.4375 22.75H9.25ZM18.6063 13.3938L18.0813 12.85L19.15 13.9187L18.6063 13.3938Z"
                                    fill="#2D2F31"
                                  />
                                </g>
                              </svg>
                            </span>

                          </div>
                        </div>
                          <ErrorMassage
                            error={errors?.photoURL}
                            visible={touched?.photoURL}
                            className='error'
                          />

                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
             
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <Formik
        initialValues={{
          ...oldData,
          image: oldData?.photoURL?.id,
          id: undefined,
          last_name: lastName,
        }}
        onSubmit={updateProfileHandler}
        validationSchema={ProfileSchema}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className="create-course-sibebar">
            <Modal
              size="md"
              show={lgShow}
              onHide={() => {
                setLgShow(false);
                setSearchValue("");
              }}
              aria-labelledby="contained-modal-title-vcenter"
              className="avaterModal"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Avatar
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="filter-bar">
                  <FilterBar
                    placeholder="Search avatar folder"
                    widthStyle="true"
                    handleSearch={handleSearch}
                  />
                  <Button className="custom-button">
                    <IoMdAdd />
                  </Button>
                </div>
                <div className="container py-4">
                  <div className="row">
                    {filteredItems.length > 0 ? (
                      filteredItems?.map((folder, index) => {
                        return (
                          <div
                            className="col-xl-3 col-md-6 mb-3"
                            onClick={() => {
                              openContent(setFieldValue);
                              setSelectedAvatar({
                                folderKey: folder.key,
                                folderName: folder.name,
                              });
                            }}
                            key={index}
                          >
                            <AvaterCard folder={folder} />
                          </div>
                        );
                      })
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="light"
                  style={{ width: "15%", padding: "10px ,20px" }}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  style={{ width: "15%", padding: "10px ,20px" }}
                >
                  {/* Ok */}
                  save
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </Formik>
      {/* </form> */}

      {/* Avater Modal */}

      <Modal
        centered
        size="xl"
        show={modelState}
        className="edit-profile profile-img-modal"
        onHide={closeChooseAvatar}
      >
        <Modal.Body>
          <ChooseAvatarModal selectedAvatar={selectedAvatar} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProfileCard;
